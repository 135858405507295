<template>
    <v-dialog
      value="true"
      persistent
      max-width="500px">
      <v-form
        v-model="valid"
        ref="form"
        lazy-validation
        @submit.prevent="confirm"
      >
        <v-card>
          <v-card-title>
            <span class="headline">Activar autenticación en dos fases (2FA)</span>
          </v-card-title>
          <v-card-text>
            <v-container grid-list-md>
              <v-row>
                <v-col>
                  <v-text-field
                    persistent-placeholder
                    label="Contraseña *"
                    :type="showPwd ? 'text' : 'password'"
                    v-model="password"
                    :append-icon="showPwd ? mdiEyeOff : mdiEye"
                    @click:append="() => {(showPwd = !showPwd)}"
                    :rules="passwordRules"
                    :error-messages="passwordCustomError"
                    tabindex=101
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field
                    persistent-placeholder
                    label="Código 2FA *"
                    type="text"
                    v-model="token"
                    :rules="tokenRules"
                    :error-messages="tokenCustomError"
                    tabindex=102
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <small>*requerido</small>
                  <alert
                    :alert="alert"
                  ></alert>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-btn
              text
              @click="cancel"
            >
              Cancelar
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              type="submit"
              color="primary"
              text
              tabindex=104
            >
              Activar autenticación 2FA
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
</template>

<script>
import EventBus from '@/components/EventBus'
import Alert from '@/components/Alert'
import api from '../api'
import { mdiEyeOff, mdiEye } from '@mdi/js'

export default {
  components: {
    Alert
  },
  data () {
    return {
      mdiEyeOff: mdiEyeOff,
      mdiEye: mdiEye,
      alert: {},
      valid: false,
      showPwd: false,
      password: '',
      passwordRules: [
        (v) => !!v || 'Se requiere una contraseña'
      ],
      passwordCustomError: [],
      token: '',
      tokenRules: [
        (v) => !!v || 'Se requiere un código 2FA'
      ],
      tokenCustomError: []
    }
  },
  methods: {
    confirm: function () {
      if (!this.$refs.form.validate()) {
        return
      }
      const data = {
        token: this.token,
        password: this.password
      }
      api.enableTwoFactor(data)
        .then(() => {
          EventBus.$emit('enable2fa', true)
        })
        .catch(e => {
          this.alert = {
            show: true,
            text: e.message,
            type: 'error'
          }
        })
    },
    cancel: function () {
      EventBus.$emit('enable2fa', false)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style >
</style>
