<template>
    <v-dialog
      value="true"
      persistent
      max-width="500px"
    >
      <v-card>
        <v-card-title>
          <span class="headline">Escanea y guarda código QR para QDB2</span>
        </v-card-title>
        <v-card-text>
          <p>Escanea este código QR con your app de autenticación (Google Authenticator u otro app que soporta Time-Based One-time Password (TOTP) según RFC 6238).</p>
          <div
            class="text-center"
          >
            <img
              :src="qrcode.dataUrl"
            >
            <p
              class="headline text-xs-center"
            >
              {{qrcode.secret}}
            </p>
            <v-alert
              type="info"
              class="text-left"
            >
             Imprime este QR o copia el código secreto y guardalo en un lugar seguro.
            </v-alert>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn
            text
            @click="cancel"
          >
            Cancelar
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="confirm"
          >
            Continuar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>

<script>
import EventBus from '@/components/EventBus'

export default {
  props: {
    qrcode: Object
  },
  data () {
    return {
      alert: {}
    }
  },
  methods: {
    confirm: () => {
      EventBus.$emit('qrcode', true)
    },
    cancel: () => {
      EventBus.$emit('qrcode', false)
    }
  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style >
</style>
