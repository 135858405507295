<template>
  <v-container
    fluid
    px-2
    py-0
  >
    <v-row
      column
      class="overflowLayout overflowFlex"
    >
      <v-col
      >
        <v-card
          flat
        >
          <v-form
            v-model="valid"
            ref="form"
            lazy-validation
          >
            <v-container
              grid-list-lg
            >
              <v-row>
                <v-col>
                  <v-card flat>
                    <v-card-text>
                      <v-switch
                        label="Tema oscuro"
                        v-model="dark"
                      ></v-switch>
                      <p>Cambia entre el tema claro y el tema oscuro en este dispositivo.</p>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-card flat>
                    <v-card-text>
                      <v-switch
                        label="Mostrar elemento al inicio de lista de elementos"
                        v-model="showItemInline"
                      ></v-switch>
                      <p>Mostrar los detalles de un elemento encima de la lista de elementos en vez de abrirlo en una página nueva. Se aplica en este dispositivo y sólo a los módulos que soportan esta opción (acciones y ventas).</p>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-card flat>
                    <v-card-text>
                      <v-switch
                        label="Activar autenticación en dos fases (2FA)"
                        v-model="twoFactor"
                      ></v-switch>
                      <p>Activar autenticación en dos fases (2FA) con Google Authenticator o otra app que soporta Time-Based One-time Password (TOTP) definido en RFC 6238.</p>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card>
        <modal2fa-qrcode
          v-if="openModalQrcode"
          :qrcode="qrcode"
        >
        </modal2fa-qrcode>
        <modal2fa-enable
          v-if="openModal2faEnable"
        >
        </modal2fa-enable>
        <modal2fa-disable
          v-if="openModal2faDisable"
        >
        </modal2fa-disable>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import EventBus from '@/components/EventBus'
import Modal2faQrcode from '@/components/Modal2faQrcode'
import Modal2faEnable from '@/components/Modal2faEnable'
import Modal2faDisable from '@/components/Modal2faDisable'
import api from '../api'

export default {
  components: {
    Modal2faQrcode,
    Modal2faEnable,
    Modal2faDisable
  },
  data () {
    return {
      alert: {},
      qrcode: {},
      valid: false,
      dark: this.$store.state.dark,
      showItemInline: this.$store.state.showItemInline,
      twoFactor: this.$store.state.user.twoFactor,

      openModalQrcode: false,
      openModal2faEnable: false,
      openModal2faDisable: false
    }
  },
  computed: {
    isDirty () {
      return (
        this.dark !== this.$store.state.dark ||
        this.showItemInline !== this.$store.state.showItemInline ||
        this.twoFactor !== this.$store.state.user.twoFactor
      )
    }
  },
  watch: {
    dark () {
      // only save locally and as cookie
      window.localStorage.setItem('dark', this.dark)
      this.$store.commit('dark', this.dark)
      this.$store.commit('alert',
        {
          show: true,
          text: 'Tema cambiado',
          type: 'success'
        }
      )
    },
    showItemInline () {
      // only save locally and as cookie
      window.localStorage.setItem('showItemInline', this.showItemInline)
      this.$store.commit('showItemInline', this.showItemInline)
      this.$store.commit('alert',
        {
          show: true,
          text: 'Configuración cambiada',
          type: 'success'
        }
      )
    },
    twoFactor () {
      // user wants to enable 2fa
      if (!this.$store.state.user.twoFactor && this.twoFactor) {
        this.prepareTwoFactor()
      }
      // user wants to disable 2fa
      if (this.$store.state.user.twoFactor && !this.twoFactor) {
        this.disableTwoFactor()
      }
    }

  },
  methods: {
    prepareTwoFactor () {
      // get provisional qrcode
      api.prepareTwoFactor()
        .then((data) => {
          console.log(data)
          this.openModalQrcode = true
          this.qrcode = data
          // qrcode event will be emited by Modal2faQrcodeDlg component
          EventBus.$on('qrcode', (data) => {
            EventBus.$off('qrcode')
            this.openModalQrcode = false
            this.qrcode = {}
            if (!data) {
              this.twoFactor = false
              return
            }
            this.enableTwoFactor()
          })
        })
        .catch(e => {
          this.twoFactor = false
          this.$store.commit('alert',
            {
              show: true,
              text: e.message,
              type: 'error'
            }
          )
        })
    },
    enableTwoFactor (data) {
      this.openModal2faEnable = true
      // enable2fa will be emited by Modal2faEnableDlg component
      EventBus.$on('enable2fa', (payload) => {
        EventBus.$off('enable2fa')
        this.openModal2faEnable = false
        if (payload === true) {
          this.$store.commit('twoFactor', true)
          this.$store.commit('alert',
            {
              show: true,
              text: 'Autenticación en dos fases activada',
              type: 'success'
            }
          )
        } else {
          this.twoFactor = false
        }
      })
    },
    disableTwoFactor (data) {
      this.openModal2faDisable = true
      // disable2fa will be emited by Modal2faDisableDlg component
      EventBus.$on('disable2fa', (payload) => {
        EventBus.$off('disable2fa')
        this.openModal2faDisable = false
        if (payload === true) {
          this.$store.commit('twoFactor', false)
          this.$store.commit('alert',
            {
              show: true,
              text: 'Autenticación en dos fases desactivada',
              type: 'success'
            }
          )
        } else {
          this.twoFactor = true
        }
      })
    }
  }
}
</script>
