<template>
    <v-dialog
      value="true"
      persistent
      max-width="500px">
      <v-form
        v-model="valid"
        ref="form"
        lazy-validation
        @submit.prevent="confirm"
      >
        <v-card>
          <v-card-title>
            <span class="headline">Validate login with 2FA Token</span>
          </v-card-title>
          <v-card-text>
            <v-container grid-list-md>
              <v-row>
                <v-col>
                  <v-text-field
                    persistent-placeholder
                    label="2FA Token *"
                    type="text"
                    v-model="token"
                    :rules="tokenRules"
                    :error-messages="tokenCustomError"
                    tabindex=102
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <small>*requerido</small>
                  <alert
                    :alert="alert"
                  ></alert>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              text
              @click="cancel"
            >
              Cancelar
            </v-btn>
            <v-btn
              type="submit"
              color="primary"
              text
              tabindex=104
            >
              Validate login
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
</template>

<script>
import EventBus from '@/components/EventBus'
import Alert from '@/components/Alert'
import api from '../api'

export default {
  components: {
    Alert
  },
  props: {
    email: String,
    password: String
  },
  data () {
    return {
      alert: {},
      valid: false,
      token: '',
      tokenRules: [
        (v) => !!v || 'Se requiere un código 2FA'
      ],
      tokenCustomError: []
    }
  },
  watch: {
    // remove alerts when changing form input
    token () {
      this.alert.show = false
    }
  },
  methods: {
    confirm: function () {
      if (!this.$refs.form.validate()) {
        return
      }
      const data = {
        email: this.email,
        password: this.password,
        token: this.token
      }
      api.login(data)
        .then(() => {
          this.$store.dispatch('initApp')
            .then(() => {
              // everthing ok => redirect to home
              this.$router.push('/')
            })
        })
        .catch(e => {
          this.alert = {
            show: true,
            text: e.message,
            type: 'error'
          }
        })
    },
    cancel: function () {
      EventBus.$emit('twoFactorLogin', false)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style >
</style>
