<template>
  <div>
    <v-dialog
      v-if="!openModal2faLogin"
      v-model="show"
      persistent
      max-width="500px"
      hide-overlay
    >
      <v-form
        v-model="valid"
        ref="form"
        @submit.prevent="login"
      >
        <v-card>
          <v-card-title>
            <span class="headline">Iniciar sesión</span>
          </v-card-title>
          <v-card-text>
            <v-container grid-list-md>
              <v-row>
                <v-col>
                  <v-text-field
                    persistent-placeholder
                    label="Correo electrónico *"
                    type="email"
                    name="email"
                    v-model="email"
                    ref="email"
                    :rules="emailRules"
                    :error="alert.show"
                    tabindex=101
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field
                    persistent-placeholder
                    label="Contraseña *"
                    :type="showPwd ? 'text' : 'password'"
                    v-model="password"
                    ref="password"
                    :append-icon="showPwd ? mdiEyeOff : mdiEye"
                    @click:append="() => {(showPwd = !showPwd)}"
                    :rules="passwordRules"
                    :error="alert.show"
                    tabindex=102
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-checkbox
                    label="No cerrar sesión"
                    v-model="remember"
                    ref="remember"
                    tabindex=103
                  ></v-checkbox>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <small>*requerido</small>
                  <Alert
                    :alert="alert"
                  ></Alert>
                </v-col>
              </v-row>
            </v-container>
            <v-spacer></v-spacer>
            <router-link
              to="/pwdlost"
            >¿Has olvidado tu contraseña?</router-link>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              type="submit"
              color="primary"
              text
              tabindex=104
              :disabled="!valid"
            >Iniciar sesión</v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
    <modal2fa-login
      v-else
      :email="email"
      :password="password"
    >
    </modal2fa-login>
  </div>
</template>

<script>
import EventBus from '@/components/EventBus'
import Alert from '@/components/Alert'
import api from '../api'
import Modal2faLogin from '@/components/Modal2faLogin'
import { mdiEyeOff, mdiEye } from '@mdi/js'

export default {
  components: {
    Alert,
    Modal2faLogin
  },
  data () {
    return {
      mdiEyeOff: mdiEyeOff,
      mdiEye: mdiEye,
      show: true,
      showPwd: false,
      valid: false,
      alert: {},
      email: '',
      emailRules: [
        (v) => !!v || 'Se requiere un correo electrónico',
        // eslint-disable-next-line
        (v) => /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'This is not a valid email'
      ],
      password: '',
      passwordRules: [
        (v) => !!v || 'A password is required'
      ],
      remember: false,
      openModal2faLogin: false
    }
  },
  computed: {
    form () {
      return {
        email: this.email,
        password: this.password,
        remember: this.remember
      }
    }
  },
  watch: {
    // remove alert and input error states when changing form input
    form (val) {
      this.alert.show = false
    }
  },
  methods: {
    login () {
      if (!this.$refs.form.validate()) {
        return
      }
      api.login(this.form)
        .then(data => {
          console.log(data)
          if (data.twoFactor) {
            this.openModal2faLogin = true
            // twoFactorLogin will be emited by Modal2faLogin component
            EventBus.$on('twoFactorLogin', () => {
              EventBus.$off('twoFactorLogin')
              this.openModal2faLogin = false
              this.alert = {}
            })
            return
          }

          this.$store.dispatch('initApp')
            .then(() => {
              // everthing ok => redirect to home
              this.$router.push('/')
            })
        })
        .catch(e => {
          // alert.show also set inputs to error state
          this.alert = {
            show: true,
            text: e.message,
            type: 'error'
          }
        })
    }
  }
}
</script>
