<template>
  <v-container
    fluid
    style="overflow: auto"
  >
    <v-row
    >
      <v-col
      >
        <v-card
          flat
        >
          <v-card-title primary-title>
            <h4 class="text-h4">
              QDB2
            </h4>
          </v-card-title>
          <v-card-text>
            <p>
              Nueva versión de QDB basado en nodejs y vuetifyjs.
            </p>
            <p>
              App Version: {{ $store.state.appVersion }}<br>
              API Version: {{ $store.state.apiVersion }}<br>
              DB Version: {{ $store.state.dbVersion }}
            </p>
            <p>
              ¡Requiere un navegador actualizado para su funcionamiento!
            </p>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col
      >
        <v-card
          flat
        >
          <v-card-title primary-title>
            <h6 class="text-h6">
              Consejos y trucos
            </h6>
          </v-card-title>
          <v-card-text>
            <p>
              Puedes usar el teclado para muchas de las acciones en qdb2:
            </p>
            <ul>
              <li>
                F6: Buscar cliente
              </li>
              <li>
                F2: Editar Registros
              </li>
              <li>
                Ctrl + s: Guardar Registros
              </li>
              <li>
                Esc: Salir de modo edición
              </li>
              <li>
                Up/Down, PageNext, PagePrev: Moverse en tablas
              </li>
              <li>
                Tab: Moverse entre campos de edición
              </li>
            </ul>
            <br>
            <p>
              Puedes modificar el comportamiento y el diseño de la applicación para tu <a href="/#/usersettings">usuario</a>.
            </p>
            <p>
              Recuerda que hay otros métodos abreviados de teclado en tu sistema operativo y tu navegador que te facilitan el trabajo.
            </p>
            <ul>
              <li>
                 Alt + Izq/Dcha: Moverte a páginas anteriores/posteriores de tu historial de navegación.
              </li>
              <li>
                 Ctrl + (Shift) + Tab: Moverte a pestañas anteriores/posteriores de tu navegador
              </li>
              <li>
                Ctrl + w: Cerrar la pestaña actual de tu navegador
              </li>
              <li>
                Ctrl + t: Abrir una nueva pestaña en tu navegador
              </li>
              <li>
                y hay mucho más en
                <a href="https://support.google.com/chrome/answer/157179?hl=es" target="_blank">Chrome</a> <v-icon small>{{ mdiOpenInNew }}</v-icon>,
                <a href="https://support.mozilla.org/es/kb/accesos-directos-realiza-rapidamente-tareas-comune" target="_blank">Firefox</a> <v-icon small>{{ mdiOpenInNew }}</v-icon>,
                <a href="https://support.microsoft.com/es-es/windows/m%C3%A9todos-abreviados-de-teclado-de-windows-dcc61a57-8ff0-cffe-9796-cb9706c75eec" target="_blank">Windows</a> <v-icon small>{{ mdiOpenInNew }}</v-icon> y
                <a href="https://support.apple.com/es-es/HT201236" target="_blank">Mac</a> <v-icon small>{{ mdiOpenInNew }}</v-icon>.
              </li>
            </ul>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mdiOpenInNew } from '@mdi/js'

export default {
  data () {
    return {
      mdiOpenInNew: mdiOpenInNew
    }
  }
}
</script>
