<template>
  <v-row row align-center>
    <v-col class="text-xs-center">
      <v-progress-circular text-xs-center
        indeterminate
        size="70"
        width="7"
        color="primary"
      >
      </v-progress-circular>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'Loader'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
