<template>
  <v-dialog
    v-model="show"
    persistent
    max-width="500px"
    hide-overlay
  >

    <div v-if="status==='loading'">
      <v-card>
        <v-card-text>
          <loader></loader>
        </v-card-text>
      </v-card>
    </div>

    <div v-else-if="status==='message'">
      <v-card>
        <v-card-text>
          <alert
            :alert="alert"
          >
          </alert>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            text
            router-link to="/login"
          >Ir a iniciar sesión</v-btn>
        </v-card-actions>
      </v-card>
    </div>

    <div v-else>
      <v-form
        v-model="valid"
        ref="form"
        lazy-validation
        @submit.prevent="submitForm"
      >
        <v-card>
          <v-card-title>
            <span class="headline">Establecer contraseña</span>
          </v-card-title>
          <v-card-text>
            <v-container grid-list-md>
              <v-row>
                <v-col>
                  <v-text-field
                    persistent-placeholder
                    label="Correo electrónico *"
                    v-model="email"
                    ref="email"
                    disabled
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field
                    persistent-placeholder
                    label="Contraseña *"
                    :type="showPwd ? 'text' : 'password'"
                    v-model="password"
                    ref="password"
                    required
                    :rules="passwordRules"
                    :append-icon="showPwd ? mdiEyeOff : mdiEye"
                    @click:append="() => {(showPwd = !showPwd)}"

                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field
                    persistent-placeholder
                    label="Repetir contraseña *"
                    :type="showPwd ? 'text' : 'password'"
                    v-model="password2"
                    ref="password2"
                    required
                    :rules="password2Rules"
                    :error-messages="password2CustomError"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <small>*requerido</small>
                  <alert
                    :alert="alert"
                  ></alert>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              text
              type="submit"
            >Establecer contraseña</v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </div>
  </v-dialog>
</template>

<script>
import Loader from '@/components/Loader'
import Alert from '@/components/Alert'
import api from '../api'
import { mdiEyeOff, mdiEye } from '@mdi/js'

export default {
  components: {
    Alert,
    Loader
  },
  props: {
    authtoken: String
  },
  data () {
    return {
      mdiEyeOff: mdiEyeOff,
      mdiEye: mdiEye,
      show: true,
      showPwd: false,
      status: 'loading',
      alert: {},
      valid: false,
      email: '',
      password: '',
      passwordRules: [
        (v) => !!v || 'Se requiere una contraseña',
        // eslint-disable-next-line
        (v) => /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z]).{8,}$/.test(v) || 'Usa una contraseña de 8 o más caracteres que incluye minúsculas, mayúsculas y números'
      ],
      // passwordCustomError: [],
      password2: '',
      password2Rules: [
        (v) => !!v || 'Por favor repite la contraseña'
      ],
      password2CustomError: []
    }
  },
  mounted () {
    if (!this.authtoken) {
      this.alert = {
        show: true,
        text: 'No código 2FA ha sido facilitado',
        type: 'error'
      }
      return
    }
    this.status = 'loading'
    api.checkAuthtoken(this.authtoken)
      .then(authTokenData => {
        console.log(authTokenData)
        switch (authTokenData.action) {
          case 'resetPwd':
            this.status = 'form'
            this.email = authTokenData.email
            break

          default:
            // show message but no form
            this.status = 'message'
            this.alert = {
              show: true,
              text: 'Datos erroneos del token de autenticación.',
              type: 'error'
            }
        }
      })
      .catch(e => {
        // show message but no form
        this.status = 'message'
        this.alert = {
          show: true,
          text: e.message,
          type: 'error'
        }
      })
  },
  watch: {
    password: function (val) {
      this.samePassword()
    },
    password2: function (val) {
      this.samePassword()
    }
  },
  methods: {
    samePassword: function () {
      if (this.password2 && this.password2 !== this.password) {
        this.password2CustomError = ['The two passwords are different']
        return false
      } else {
        this.password2CustomError = []
        return true
      }
    },
    submitForm: function () {
      if (!this.$refs.form.validate() || !this.samePassword()) {
        return
      }
      const data = {
        password: this.password,
        token: this.authtoken
      }
      api.doAuthtokenAction(data)
        .then(user => {
          this.status = 'message'
          this.alert = {
            show: true,
            text: 'Ahora puedes iniciar sesión con tu nueva contraseña.',
            type: 'success'
          }
        })
        .catch(e => {
          this.status = 'message'
          this.alert = {
            show: true,
            text: e.message,
            type: 'error'
          }
        })
    }
  }
}
</script>
