<template>
  <v-alert
    transition="scale-transition"
    :color="color"
    :icon="icon"
    :value="alert.show ? true : false"
  >{{alert.text}}</v-alert>
</template>

<script>
import { mdiCheckCircle, mdiAlertOctagon, mdiAlert, mdiInformation } from '@mdi/js'

export default {
  name: 'Alert',
  props: {
    alert: Object
  },
  data () {
    return {
      icon: null,
      color: null
    }
  },
  mounted () {
    this.actualize()
  },
  watch: {
    alert: function (val) {
      this.actualize()
    }
  },
  methods: {
    actualize: function () {
      // set icons automatically
      switch (this.alert.type) {
        case 'success':
          this.color = 'success'
          this.icon = mdiCheckCircle
          break
        case 'warning':
          this.color = 'warning'
          this.icon = mdiAlertOctagon
          break
        case 'error':
          this.color = 'error'
          this.icon = mdiAlert
          break
        default:
          this.color = 'info'
          this.icon = mdiInformation
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
