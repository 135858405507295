<template>
  <v-dialog v-model="show" persistent max-width="500px">
    <div v-if="status==='message'">
      <v-card>
        <v-card-title>
          <span class="headline">Reestablecer contraseña</span>
        </v-card-title>
        <v-card-text>
        <alert
          :alert="alert"
        ></alert>
        </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              text
              tabindex=105
              router-link to="/"
            >Volver</v-btn>
          </v-card-actions>
      </v-card>
    </div>

    <div v-else-if="status==='loading'">
      <v-card>
        <v-card-title>
          <span class="headline">Reestablecer contraseña</span>
        </v-card-title>
        <v-card-text>
          <loader></loader>
        </v-card-text>
      </v-card>
    </div>

    <div v-else>
      <v-form
        v-model="valid"
        ref="form"
        @submit.prevent="resetPassword"
      >
        <v-card>
          <v-card-title>
            <span class="headline">Reestablecer contraseña</span>
          </v-card-title>
          <v-card-text>
            <p>Te enviaremos un correo electrónico con un enlace para reestablecer tu contraseña.</p>
            <v-container grid-list-md>
              <v-row>
                <v-col>
                  <v-text-field
                    v-model="email"
                    label="Correo electrónico *"
                    type="email"
                    name="email"
                    ref="email"
                    :rules="emailRules"
                    :error="alert.show"
                    tabindex=101
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <small>*requerido</small>
                  <alert
                    :alert="alert"
                  ></alert>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-btn
              text
              v-on:blur="goToTabStart()"
              tabindex=104
              router-link to="/login"
            >Iniciar sesión</v-btn>
            <v-spacer></v-spacer>
            <v-btn
              type="submit"
              color="primary"
              text
              tabindex=103
            >Reestablecer contraseña</v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </div>
  </v-dialog>
</template>

<script>
import Loader from '@/components/Loader'
import Alert from '@/components/Alert'
import api from '../api'

export default {
  name: 'ResetPwd',
  components: {
    Alert,
    Loader
  },
  data () {
    return {
      show: true,
      status: 'form',
      valid: false,
      alert: {},
      email: '',
      emailRules: [
        (v) => !!v || 'Se requiere un correo electrónico',
        // eslint-disable-next-line
        (v) => /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'Esto no es un correo electrónico válido'
      ]
    }
  },
  computed: {
    form () {
      return {
        email: this.email,
        password: this.password
      }
    }
  },
  watch: {
    // close alert when changing form
    form (val) {
      this.alert.show = false
    }
  },
  methods: {
    goToTabStart () {
      this.$nextTick(function () {
        this.$refs.email.focus()
      })
    },
    resetPassword () {
      if (!this.$refs.form.validate()) {
        return
      }
      this.status = 'loading'
      const data = {
        email: this.email,
        action: 'resetPwd'
      }
      api.createAuthtokenAction(data)
        .then(() => {
          this.status = 'message' // show message but no form
          this.alert = {
            show: true,
            text: 'Te hemos enviado un correo electrónico con un enlace para reestablecer tu contraseña.',
            type: 'success'
          }
        })
        .catch(e => {
          this.status = 'primary' // show message in form
          this.alert = {
            show: true,
            text: e.message,
            type: 'error'
          }
        })
    }
  }
}
</script>
